import { APICore } from "./apiCore";

const api = new APICore();

const getRelatorioDadosGerais = (queryString?: string) => {
  return api.get(`/relatorio-dados-gerais${queryString ? queryString : ""}`, {
    responseType: "blob",
  });
};

export { getRelatorioDadosGerais };
