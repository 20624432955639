import { APICore } from './apiCore';

const api = new APICore();

interface ISendAditiveContractClicksign {
	contrato_id: number;
	supervisor_associado_id: number;
	paciente_id: number;
}

const sendAditiveContractClicksign = (data: ISendAditiveContractClicksign) => {
	return api.create(`/contrato-aditivo`, data);
};

export { sendAditiveContractClicksign };
