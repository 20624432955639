import { APICore } from "./apiCore";

const api = new APICore();

const criarFeriado = (params: { descricao: string }) => {
  return api.create("/feriado", params);
};

const atualizarFeriado = (id: string, params: { descricao: string }) => {
  return api.update(`/feriado/${id}`, params);
};

const deletarFeriado = (id: string) => {
  return api.delete(`/feriado/${id}`);
};

const getFeriados = (queryString?: string) => {
  return api.get(`/feriado${queryString ? queryString : ""}`);
};

const getFeriado = (id: string) => {
  return api.get(`/feriado/${id}`);
};

export {
  criarFeriado,
  atualizarFeriado,
  deletarFeriado,
  getFeriados,
  getFeriado,
};
