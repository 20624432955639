async function compressImage(file: File | Blob) {
  const dataURI: any = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(file);
  });

  const img = document.createElement("img");
  img.src = dataURI!;

  document.body.appendChild(img);

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const originalWidth = img.width;
  const originalHeight = img.height;

  const canvasWidth = originalWidth;
  const canvasHeight = originalHeight;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  context!.drawImage(img, 0, 0, originalWidth, originalHeight);

  document.body.removeChild(img);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      }
    }, file.type);
  });
}

export default compressImage;
