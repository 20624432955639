import React, { createContext, useContext, useState } from "react";

interface IPreCadastro {
    updatingId: any;
    setUpdatingId: React.Dispatch<React.SetStateAction<any>>;
    modal: boolean;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreCadastroContext = createContext({} as IPreCadastro);

const PreCadastroProvider: React.FC = ({ children }) => {
    const [updatingId, setUpdatingId] = useState();
    const [modal, setModal] = useState<boolean>(false);

    return (
        <PreCadastroContext.Provider
            value={{ updatingId, setUpdatingId, modal, setModal }}
        >
            {children}
        </PreCadastroContext.Provider>
    );
};

const usePreCadastro = () => {
    const preCadastro = useContext(PreCadastroContext);
    return preCadastro;
};

export { PreCadastroProvider, usePreCadastro };
