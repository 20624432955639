import React from "react";
import { Button } from "react-bootstrap";
import { setLoadingState } from "../redux/actions";

interface IListItem {
    fetchFn: (queryString?: string) => Promise<any>;
    queryString: string;
    setState: React.Dispatch<React.SetStateAction<any[]>>;
    setApiRespose: React.Dispatch<React.SetStateAction<any>>;
    dispatch: any;
    pageNumber: number;
    actualPage: number;
}

const ListItem: React.FC<IListItem> = ({
    fetchFn,
    queryString,
    setState,
    setApiRespose,
    dispatch,
    pageNumber,
    actualPage,
}) => {
    return (
        <li
            className={`page-item ${actualPage === pageNumber ? "active" : ""}`}
        >
            <Button
                className="page-link"
                onClick={async () => {
                    try {
                        dispatch(setLoadingState(true));
                        const {data: {result, ...rest}} = await fetchFn(
                            `?pageSize=15&currentPage=${pageNumber}${queryString}`
                        );
                        setState(result);
                        setApiRespose(rest);
                        dispatch(setLoadingState(false));
                    } catch (error) {
                        console.log({ error });
                    }
                }}
            >
                {pageNumber}
            </Button>
        </li>
    );
};

const createPagination = (
    fetchFn: (queryString?: string) => Promise<any>,
    queryString: string,
    setState: React.Dispatch<React.SetStateAction<any[]>>,
    setApiRespose: React.Dispatch<React.SetStateAction<any>>,
    dispatch: any,
    actualPage: number,
    totalPages: number,
) => {
    const pages = [];

    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
        pages.push(
            ListItem({
                fetchFn,
                queryString,
                setState,
                setApiRespose,
                dispatch,
                pageNumber,
                actualPage,
            })
        );
    }

    return pages as any;
};

export { createPagination };
