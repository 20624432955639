import { APICore } from "./apiCore";

const api = new APICore();

const getPacientesAtendidos = (id: string) => {
    return api.get(`/pacientes-atendidos/${id}`);
};

export {
    getPacientesAtendidos
};