export const getFileIcon = (fileName: string, tag: 'file' | 'folder') => {
  if (tag === 'folder') {
    return "mdi-folder-outline"
  }

  switch (true) {
    case fileName.includes(".pdf"):
      return "mdi-file-pdf";
    case fileName.includes(".xlsx"):
      return "mdi-file-excel";
    case fileName.includes(".xls"):
      return "mdi-file-excel";
    case fileName.includes(".docx"):
      return "mdi-file-word";
    case fileName.includes(".doc"):
      return "mdi-file-word";
    case fileName.includes(".jpg"):
    case fileName.includes(".png"):
    case fileName.includes(".svg"):
    case fileName.includes(".jpeg"):
    case fileName.includes(".gif"):
      return "mdi-file-image";
    default:
      return "mdi-file-document-outline";
  }
};
