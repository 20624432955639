import { IArquivoExtended, IArquivo } from "types";

type ReactSetState = React.Dispatch<React.SetStateAction<IArquivoExtended[]>>;

export function splitFilesFromVideos(
  arquivos: IArquivo[],
  setVideos: ReactSetState,
  setArquivos: ReactSetState
) {
  const videoFiles: IArquivoExtended[] = [];
  const generalFiles: IArquivoExtended[] = [];

  const formats = [
    { name: ".avi", mime: "video/x-msvideo" },
    { name: ".mpeg", mime: "video/mpeg" },
    { name: ".ogv", mime: "video/ogg" },
    { name: ".webm", mime: "video/webm" },
    { name: ".mp4", mime: "application/mp4" },
  ];

  for (const arquivo of arquivos) {
    let isVideo = false;
    for (const format of formats) {
      if (arquivo.nome.includes(format.name)) {
        arquivo.mime = format.mime;
        videoFiles.push(arquivo as IArquivoExtended);
        isVideo = true;
        break;
      }
    }
    if (!isVideo) {
      generalFiles.push(arquivo as IArquivoExtended);
    }
  }
  setVideos(videoFiles);
  setArquivos(generalFiles);
}

export function getFiles(
  arquivos: IArquivo[],
  setArquivos: React.Dispatch<React.SetStateAction<IArquivo[]>>
) {
  const generalFiles: IArquivo[] = [];

  const formats = [
    { name: ".avi", mime: "video/x-msvideo" },
    { name: ".mpeg", mime: "video/mpeg" },
    { name: ".ogv", mime: "video/ogg" },
    { name: ".webm", mime: "video/webm" },
    { name: ".mp4", mime: "application/mp4" },
  ];

  for (const arquivo of arquivos) {
    for (const format of formats) {
      if (!arquivo.nome.includes(format.name)) {
        generalFiles.push(arquivo);
        break;
      }
    }
  }
  setArquivos(generalFiles);
}
