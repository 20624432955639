import { APICore } from "./apiCore";

const api = new APICore();

const updateMeuPerfil = (params: any) => {
    return api.update(`/meu-perfil/`, params);
};

const getMeuPerfil = () => {
    return api.get(`/meu-perfil/`);
};

export { updateMeuPerfil, getMeuPerfil };
