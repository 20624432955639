import { APICore } from "./apiCore";

const api = new APICore();

const getRelatorioContrato = (queryString?: string) => {
    return api.get(`/relatorio-contrato${queryString ? queryString : ''}`, {responseType: 'blob'})
}

export {
    getRelatorioContrato
}