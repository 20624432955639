import { APICore } from "./apiCore";

const api = new APICore();

const criarHistorico = (params: object) => {
  return api.create("/historico", params);
};

const atualizarHistorico = (id: string | number, params: any) => {
  return api.update(`/historico/${id}`, params);
};

const deletarHsitorico = (id: string) => {
  return api.delete(`/historico/${id}`);
};

const getHistoricos = (queryString?: string) => {
  return api.get(`/historico${queryString ? queryString : ""}`);
};

const getHistorico = (id: string) => {
  return api.get(`/agendamento/${id}`);
};

export {
  criarHistorico,
  atualizarHistorico,
  deletarHsitorico,
  getHistoricos,
  getHistorico,
};
