import jwtDecode from "jwt-decode";
import axios, { AxiosRequestConfig } from "axios";

import config from "../../config";
import { LOCAL_STORAGE_AUTHORIZATION_ERROR_KEY } from "constants/keys";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;
const AUTH_SESSION_KEY = "envolve_user";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    if (error?.response?.status === 401) {
      const api = new APICore();
      api.resetAuth();

      setTimeout(() => {
        window.location.pathname = "/login";
      }, 3500);
    }

    return Promise.reject(error);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
  const user = localStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};
class APICore {
  /**
   * Fetches data from given url
   */
  get<T = any>(url: string, params?: any) {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get<T>(
        `${url}${url.includes("?") ? "&" : "?"}${queryString}`,
        params
      );
    } else {
      response = axios.get<T>(`${url}`, params);
    }
    return response;
  }

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, {
        responseType: "blob",
      });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create<T = any>(url: string, data: any, config?: any) {
    return axios.post<T>(url, data, config);
  }

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return axios.put<T>(url, data, config);
  }

  /**
   * Deletes data
   */
  delete = (url: string, config?: AxiosRequestConfig) => {
    return axios.delete(url, config);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user) {
      return false;
    }
    try {
      const decoded: any = jwtDecode(user.token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        this.resetAuth();
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log("e: ", { error });
      this.resetAuth();
      return false;
    }
  };

  setLoggedInUser = (session?: any) => {
    if (session)
      localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      localStorage.removeItem(AUTH_SESSION_KEY);
    }
  };
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = localStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };

  resetAuth = () => {
    this.setLoggedInUser(null);
    setAuthorization(null);
    localStorage.removeItem(AUTH_SESSION_KEY);
    localStorage.setItem(
      LOCAL_STORAGE_AUTHORIZATION_ERROR_KEY,
      JSON.stringify({
        error: "Token expirado, faça login novamente para continuar",
      })
    );
  };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
