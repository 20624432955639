import { APICore } from "./apiCore";
import { AxiosRequestConfig } from "axios";

const api = new APICore();

const criarAtendimento = (params: { descricao: string }) => {
  return api.create("/atendimento", params);
};

const atualizarAtendimento = (id: string, params: object) => {
  return api.update(`/atendimento/${id}`, params);
};

const deletarAtendimento = (id: string) => {
  return api.delete(`/atendimento/${id}`);
};

const getAtendimentos = (
  queryString?: string,
  requestConfig?: AxiosRequestConfig
) => {
  return api.get(
    `/atendimento${queryString ? queryString : ""}`,
    requestConfig
  );
};

const getAtendimentosCalendario = (queryString?: string) => {
  return api.get(`/atendimento-calendario${queryString ? queryString : ""}`);
};

const getAtendimento = (id: string | number) => {
  return api.get(`/atendimento/${id}`);
};

export {
  criarAtendimento,
  atualizarAtendimento,
  deletarAtendimento,
  getAtendimentos,
  getAtendimentosCalendario,
  getAtendimento,
};
