import { APICore } from "./apiCore";

const api = new APICore();

const criarIndisponibilidade = (params: any) => {
    return api.create("/indisponibilidade", params);
};

const atualizarIndisponibilidade = (id: string, params: any) => {
    return api.update(`/indisponibilidade/${id}`, params);
};

const deletarIndisponibilidade = (id: string) => {
    return api.delete(`/indisponibilidade/${id}`);
};
const listarIndisponibilidades = (queryString?: string) => {
    return api.get(`/indisponibilidade${queryString ? queryString : ""}`);
};

export {
    criarIndisponibilidade,
    atualizarIndisponibilidade,
    deletarIndisponibilidade,
    listarIndisponibilidades
};
