const DEFAULT_FORMATING_PARAMS = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

/**
 * Essa função já trata casos de data inexistente;
 */

export const createParsedDate = (
  date: string,
  params?: Intl.DateTimeFormatOptions
) => {
  if (!date) return "";

  const formatingParams = params ? params : DEFAULT_FORMATING_PARAMS;

  return Intl.DateTimeFormat("pt-BR", formatingParams).format(new Date(date));
};
