import React, {useEffect} from 'react'

const RedirectProd: React.FC = () => {
    useEffect(() => {
        window.location.replace('https://abatech.app/precadastro-pacientes')
    }, [])

    return <div></div>
}

export default RedirectProd