import { APICore } from "./apiCore";

const api = new APICore();

const cadastrarGrupo = (params: { nome: string }) => {
    return api.create("/grupos-sistema", params);
};

const deletarGrupo = (id: string) => {
    return api.delete(`/grupos-sistema/${id}`);
};

const getGrupo = (id: string) => {
    return api.get(`/grupos-sistema/${id}`);
};

const updateGrupo = (
    id: string,
    params: { funcoes?: number[]; nome?: string }
) => {
    return api.update(`/grupos-sistema/${id}`, params);
};

const getGrupos = (queryString?: string) => {
    return api.get(`/grupos-sistema${queryString ? queryString : ""}`);
};

export { cadastrarGrupo, deletarGrupo, getGrupo, updateGrupo, getGrupos };
