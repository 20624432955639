import { APICore } from "./apiCore";

const api = new APICore();

const deleteUser = (id: string) => {
    return api.delete(`/usuarios/${id}`);
};

const createUser = (params: {
    nome: string;
    email: string;
    senha: string;
    gruposistema_id: string;
    celular: string;
}) => {
    return api.create("/usuarios", params);
};

const updateUser = (
    id: string,
    params: {
        nome?: string;
        email?: string;
        gruposistema_id?: string;
        celular?: string;
        avatar_id?: any;
    }
) => {
    return api.update(`/usuarios/${id}`, params);
};

const getUsers = (queryString?: string) => {
    return api.get(`/usuarios${queryString ? queryString : ""}`);
};

const getUser = (id: string) => {
    return api.get(`/usuarios/${id}`);
};

export { deleteUser, createUser, updateUser, getUsers, getUser };
