import { APICore } from "./apiCore";

const api = new APICore();

const getColaboradorAtendimentoSemana = (
  colaborador_id: number,
  queryString?: string
) => {
  return api.get(
    `/colaborador-atendimentos-semana/${colaborador_id}${
      queryString ? queryString : ""
    }`
  );
};

export { getColaboradorAtendimentoSemana };
