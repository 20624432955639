import { APICore } from "./apiCore";

const api = new APICore();

const deleteUnidade = (id: string) => {
    return api.delete(`/unidade/${id}`);
};

const createUnidade = (params: { nome: string }) => {
    return api.create("/unidade", params);
};

const updateUnidade = (
    id: string,
    params: {
        nome: string;
    }
) => {
    return api.update(`/unidade/${id}`, params);
};

const getUnidades = (queryString?: string) => {
    return api.get(`/unidade${queryString ? queryString : ""}`);
};

const getUnidade = (id: string) => {
    return api.get(`/unidade/${id}`);
};

export { deleteUnidade, createUnidade, updateUnidade, getUnidades, getUnidade };
