import { APICore } from "./apiCore";

const api = new APICore();

const criarAgendamento = (params: object) => {
  return api.create("/agendamento", params);
};

const criarAgendamentoMensal = (params: object) => {
  return api.create("/agendamento-mensal", params);
};

const atualizarAgendamento = (id: string | number, params: any) => {
  return api.update(`/agendamento/${id}`, params);
};

const deletarAgendamento = (id: string) => {
  return api.delete(`/agendamento/${id}`);
};

const getAgendamentos = (queryString?: string) => {
  return api.get(`/agendamento${queryString ? queryString : ""}`);
};

const getAgendamento = (id: string) => {
  return api.get(`/agendamento/${id}`);
};

export {
  criarAgendamento,
  atualizarAgendamento,
  deletarAgendamento,
  getAgendamentos,
  getAgendamento,
  criarAgendamentoMensal,
};
