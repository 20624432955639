import { APICore } from "./apiCore";

const api = new APICore();

const criarPaciente = (params: any) => {
    return api.create("/registro-paciente", params);
};

/*const atualizarColaborador = (id: string, params: any) => {
    return api.update(`/colaborador/${id}`, params);
};

const deletarColaborador = (id: string) => {
    return api.delete(`/colaborador/${id}`);
};*/

const listarDadosAutoRegistro = (query?: string) => {
    return api.get(`/registro-paciente${query && query}`);
};

/*const listarColaborador = (id: string) => {
    return api.get(`/colaborador/${id}`);
};*/

export {
    criarPaciente,
    /*atualizarColaborador,
    deletarColaborador,*/
    listarDadosAutoRegistro,
    // listarColaborador
};
