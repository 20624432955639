import { APICore } from './apiCore';

const api = new APICore();

const getIndicadoresAtendimentos = (queryString: string) => {
    return api.get(`/indicadores-atendimento${queryString}`);
}

export {
    getIndicadoresAtendimentos
}
