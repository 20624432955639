import { APICore } from "./apiCore";

const api = new APICore();

const getSelectColaboradores = (queryString?: string) => {
    return api.get(`/select-colaborador${queryString ? queryString : ''}`)
}

export {
    getSelectColaboradores
}