import React, { useState, useEffect } from "react";
import packageJson from "../../package.json";
import { isAfter } from "date-fns";

const buildDateGreaterThan = (
  latestDate: number | Date,
  currentDate: string | Date | number
) => {
  const momLatestDateTime = new Date(latestDate);
  const momCurrentDateTime = new Date(currentDate);

  if (isAfter(momLatestDateTime, momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

const ClearCacheComponent: React.FC = ({ children }) => {
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  useEffect(() => {
    if (packageJson.buildDate) {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        })
        .catch((err) => console.log({ err }));
    }

    if (!packageJson.buildDate) setIsLatestBuildDate(true);
  }, []);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  return <React.Fragment>{isLatestBuildDate ? children : null}</React.Fragment>;
};

export default ClearCacheComponent;
