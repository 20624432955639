import { APICore } from "./apiCore";
import { AxiosRequestConfig } from "axios";

const api = new APICore();

const getNotificacoes = (
  queryString?: string,
  requestConfig?: AxiosRequestConfig
) => {
  return api.get(`/notificacoes${queryString}`, requestConfig);
};

const updateNotificacoes = (data: object) => {
  return api.update("/notificacoes", data);
};

export { getNotificacoes, updateNotificacoes };
