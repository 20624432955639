import { APICore } from "./apiCore";

const api = new APICore();

const criarPaciente = (params: any) => {
    return api.create("/paciente", params);
};

const atualizarPaciente = (id: string, params: any) => {
    return api.update(`/paciente/${id}`, params);
};

const deletarPaciente = (id: any) => {
    return api.delete(`/paciente/${id}`);
};

const listarPacientes = (queryString?: string) => {
    return api.get(`/paciente${queryString ? queryString : ""}`);
};

const listarPaciente = (id: string) => {
    return api.get(`/paciente/${id}`);
};

export {
    criarPaciente,
    listarPacientes,
    atualizarPaciente,
    deletarPaciente,
    listarPaciente
};
