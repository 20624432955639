import { APICore } from "./apiCore";

const api = new APICore();

const deleteTipoHorario = (id: string) => {
  return api.delete(`/tipo-horario/${id}`);
};

const createTipoHorario = (params: { nome: string }) => {
  return api.create("/tipo-horario", params);
};

const updateTipoHorario = (
  id: string,
  params: { especialidades?: number[]; nome?: string }
) => {
  return api.update(`/tipo-horario/${id}`, params);
};

const getTipoHorarios = (queryString?: string) => {
  return api.get(`/tipo-horario${queryString ? queryString : ""}`);
};

const getTipoHorario = (id: string, queryStr?: string) => {
  return api.get(`/tipo-horario/${id}${queryStr ? queryStr : ""}`);
};

export {
  deleteTipoHorario,
  createTipoHorario,
  updateTipoHorario,
  getTipoHorarios,
  getTipoHorario,
};
