import { APICore } from "./apiCore";

const api = new APICore();

const criarColaborador = (params: { descricao: string }) => {
  return api.create("/colaborador", params);
};

const atualizarColaborador = (id: string, params: any) => {
  return api.update(`/colaborador/${id}`, params);
};

const deletarColaborador = (id: string) => {
  return api.delete(`/colaborador/${id}`);
};

const listarColaboradores = (queryString?: string) => {
  return api.get(`/colaborador${queryString ? queryString : ""}`);
};

const listarColaboradoresDisponiveis = (queryString?: string) => {
  return api.get(
    `/colaborador-disponibilidade${queryString ? queryString : ""}`
  );
};

const listarColaboradoresDisponiveisMensal = (queryString?: string) => {
  return api.get(`/disponibilidade-mensal${queryString ? queryString : ""}`);
};

const listarColaborador = (id: string) => {
  return api.get(`/colaborador/${id}`);
};

export {
  criarColaborador,
  atualizarColaborador,
  deletarColaborador,
  listarColaboradores,
  listarColaboradoresDisponiveis,
  listarColaborador,
  listarColaboradoresDisponiveisMensal,
};
