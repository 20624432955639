import { APICore } from "../api/apiCore";

const api = new APICore();

function funcaoApi(params: { nome: string }) {
    const baseUrl = "/funcao-sistema/";
    return api.create(`${baseUrl}`, params);
}

function deleteFuncaoApi(id: number) {
    const baseUrl = `/funcao-sistema/${id}`;
    return api.delete(baseUrl);
}

function updateFuncaoApi(id: string, params: { nome: string }) {
    const baseUrl = `/funcao-sistema/${id}`;
    return api.update(baseUrl, params);
}

function getFuncao(queryString?: string) {
    return api.get(`/funcao-sistema${queryString ? queryString : ""}`);
}

function getOneFuncao(id: string) {
    const baseUrl = `/funcao-sistema/${id}`;
    return api.get(baseUrl);
}

export { funcaoApi, deleteFuncaoApi, updateFuncaoApi, getFuncao, getOneFuncao };
