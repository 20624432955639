import { APICore } from "./apiCore";

const api = new APICore();

const getPacienteAtendimentoSemana = (
  paciente_id: number,
  queryString?: string
) => {
  return api.get(
    `/paciente-atendimentos-semana/${paciente_id}${
      queryString ? queryString : ""
    }`
  );
};

export { getPacienteAtendimentoSemana };
