import { APICore } from "./apiCore";
import compressImage from "../compressImage";

const api = new APICore();

const criarArquivo = async (
  files: FileList | Blob[],
  tipoarquivo_id = "1",
  relacionamento_id = "",
  compressImageValidation = false,
  relacionamentoArquivo = "historico_id"
) => {
  const formData = new FormData();
  for (const file of Array.from(files)) {
    if (compressImageValidation) {
      if (file.type !== "image/gif") {
        const cImage = await compressImage(file);
        formData.append("arquivos", cImage as any);
      }

      formData.append("arquivos", file);

      continue;
    }
    formData.append("arquivos", file);
  }

  formData.append("tipoarquivo_id", tipoarquivo_id);
  formData.append(relacionamentoArquivo, relacionamento_id);

  return api.create("/arquivo", formData, {
    headers: { "Content-type": "multipart/form-data" },
  });
};

const deletarArquivo = (id: string) => {
  return api.delete(`/arquivo/${id}`);
};

export { criarArquivo, deletarArquivo };
