import { APICore } from "./apiCore";

const api = new APICore();

const criarSala = (params: {
    nome: string;
    capacidade: number;
    perfilpaciente_id: number;
}) => {
    return api.create("/salas", params);
};

const atualizarSala = (id: string, params: any) => {
    return api.update(`/salas/${id}`, params);
};

const deletarSala = (id: string) => {
    return api.delete(`/salas/${id}`);
};

const getSalas = (queryString?: string) => {
    return api.get(`/salas${queryString ? queryString : ""}`);
};

const getSalasDisponiveis = (queryString?: string) => {
    return api.get(`/sala-disponibilidade${queryString ? queryString : ""}`);
};

const getSala = (id: string) => {
    return api.get(`/salas/${id}`);
};

export { criarSala, atualizarSala, deletarSala, getSala, getSalas, getSalasDisponiveis };
