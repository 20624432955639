import { APICore } from "./apiCore";

const api = new APICore();

const criarProntuario = (params: any) => {
    return api.create("/prontuario", params);
};

const atualizarProntuario = (
    id: string | number,
    params: any
) => {
    return api.update(`/prontuario/${id}`, params);
};

export { criarProntuario, atualizarProntuario };
