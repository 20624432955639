import { APICore } from "./apiCore";

const api = new APICore();

const criarCargo = (params: { descricao: string }) => {
    return api.create("/cargos", params);
};

const atualizarCargo = (id: string, params: { descricao: string }) => {
    return api.update(`/cargos/${id}`, params);
};

const deletarCargo = (id: string) => {
    return api.delete(`/cargos/${id}`);
};

const getCargos = (queryString?: string) => {
    return api.get(`/cargos${queryString ? queryString : ""}`);
};

const getCargo = (id: string) => {
    return api.get(`/cargos/${id}`);
};

export { criarCargo, atualizarCargo, deletarCargo, getCargos, getCargo };
