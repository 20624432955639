import { APICore } from "./apiCore";

const api = new APICore();

const criarPerfilPaciente = (params: { descricao: string; cor: string }) => {
    return api.create("/perfil-paciente", params);
};

const atualizarPerfilPaciente = (
    id: string,
    params: { descricao: string; cor: string }
) => {
    return api.update(`/perfil-paciente/${id}`, params);
};

const deletarPerfilPaciente = (id: string) => {
    return api.delete(`/perfil-paciente/${id}`);
};

const getPerfilPacientes = (queryString?: string) => {
    return api.get(`/perfil-paciente${queryString ? queryString : ""}`);
};

const getPerfilPaciente = (id: string) => {
    return api.get(`/perfil-paciente/${id}`);
};

export {
    criarPerfilPaciente,
    atualizarPerfilPaciente,
    deletarPerfilPaciente,
    getPerfilPaciente,
    getPerfilPacientes
};
