import { put } from "helpers";
import { IFeedback } from "types";

export async function markPostAsViwed(
  feedback: IFeedback,
  colaboradorId: number
) {
  if (feedback.colaborador_id == colaboradorId && !feedback.visualizado) {
    try {
      await put(`/feedback/${feedback.id}`, { ...feedback, visualizado: true });
    } catch (error) {
      console.log({ error });
    }
  }
}
