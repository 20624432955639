import { APICore } from "./apiCore";

const api = new APICore();
const getQuadro = (id: string | number, queryStr?: string) => {
  return api.get(`/quadro/${id}${queryStr ? queryStr : ""}`);
};

const getColaboradoresDisponiveisQuadro = (queryStr?: string) => {
  return api.get(
    `/disponibilidade-colaborador-atendimento${queryStr ? queryStr : ""}`
  );
};

export { getQuadro, getColaboradoresDisponiveisQuadro };
