import { APICore } from "./apiCore";

const api = new APICore();

const criarContrato = (params: any) => {
    return api.create("/contrato", params);
};

const atualizarContrato = (id: string, params: any) => {
    return api.update(`/contrato/${id}`, params);
};

const deletarContrato = (id: any) => {
    return api.delete(`/contrato/${id}`);
};

const listarContrato = (id: string) => {
    return api.get(`/contrato/${id}`);
};

const listarContratos = (queryString?: string) => {
    return api.get(`/contrato${queryString ? queryString : ""}`);
};

export {
    criarContrato,
    atualizarContrato,
    deletarContrato,
    listarContrato,
    listarContratos
};
