import { APICore } from "./apiCore";

const api = new APICore();

const getSelectPaciente = (queryString?: string) => {
    return api.get(`/select-paciente${queryString ? queryString : ''}`)
}

export {
    getSelectPaciente
}