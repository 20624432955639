import { APICore } from "./apiCore";

const api = new APICore();

const sendContractsClicksign = (pacienteId: number) => {
    return api.get(`/enviar-contratos/paciente/${pacienteId}`);
};

const sendContractClicksign = (contratoId: number, socioId: number) => {
    return api.get(`/enviar-contratos/contrato/${contratoId}/${socioId}`);
};

export { sendContractsClicksign, sendContractClicksign };
