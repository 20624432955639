import { APICore } from "./apiCore";

const api = new APICore();

const criarPlanoSaude = (params: { descricao: string }) => {
  return api.create("/planosaude", params);
};

const atualizarPlanoSaude = (id: string, params: { descricao: string }) => {
  return api.update(`/planosaude/${id}`, params);
};

const deletarPlanoSaude = (id: string) => {
  return api.delete(`/planosaude/${id}`);
};

const getPlanoSaudes = (queryString?: string) => {
  return api.get(`/planosaude${queryString ? queryString : ""}`);
};

const getPlanoSaude = (id: string) => {
  return api.get(`/planosaude/${id}`);
};

export {
  criarPlanoSaude,
  atualizarPlanoSaude,
  deletarPlanoSaude,
  getPlanoSaude,
  getPlanoSaudes,
};
