import { APICore } from "./apiCore";

const api = new APICore();

const getHorarioFuncionamento = (queryString?: string) => {
    return api.get(`/horario-funcionamento${queryString ? queryString : ""}`);
};

export {
    getHorarioFuncionamento
};