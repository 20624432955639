import { APICore } from "./apiCore";

const api = new APICore();

const getProntuarioPaciente = (queryString?: string | undefined) => {
  const { pathname } = window.location;
  const id = pathname.replace(/[^0-9]/g, "");
  return api.get(`/prontuario-paciente/${id}${queryString ? queryString : ""}`);
};

export { getProntuarioPaciente };
