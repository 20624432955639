import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const FirstAccess = React.lazy(() => import("../pages/auth/FirstAccess"));
const RegistrarPaciente = React.lazy(
  () => import("../pages/auth/RegistroPaciente")
);

const Unidades = React.lazy(
  () => import("../pages/apps/configuracoes/unidades")
);

// dashboard
const Dashboard = React.lazy(
  () => import("../pages/dashboard/Dashboard/MainDashboard/")
);

const CalendarioApp = React.lazy(() => import("../pages/apps/Calendario/"));

// list users
const Users = React.lazy(() => import("../pages/apps/usuarios/usuarios"));

// update user
const CriarAtualizarUsuario = React.lazy(
  () => import("../pages/apps/usuarios/usuarios/form")
);

const ListagemHorario = React.lazy(
  () => import("../pages/apps/configuracoes/horarios")
);

const ListagemTipoHorario = React.lazy(
  () => import("../pages/apps/configuracoes/tipoHorario")
);
const CreateTipoHorario = React.lazy(
  () => import("../pages/apps/configuracoes/tipoHorario/form")
);
const UpdateTipoHorario = React.lazy(
  () => import("../pages/apps/configuracoes/tipoHorario/update")
);
const ChecklistsPersonalizados = React.lazy(
  () => import("../pages/apps/configuracoes/checklist-personalizado")
);
const ListagemTipoContrato = React.lazy(
  () => import("../pages/apps/configuracoes/tipoContrato")
);
const ListagemEmpresas = React.lazy(
  () => import("../pages/apps/configuracoes/empresas")
);
const ProtocoloSocialEdicao = React.lazy(
  () => import("../pages/apps/configuracoes/protocoloSocial")
);
const ProtocoloSocialVisualizar = React.lazy(
  () => import("../pages/apps/ProtocoloSocial")
);
const ListagemTipoFeedback = React.lazy(
  () => import("../pages/apps/configuracoes/tipoFeedback")
);

// system groups
const SystemGroups = React.lazy(
  () => import("../pages/apps/usuarios/gruposSistema")
);

const CreateGroupsSystem = React.lazy(
  () => import("../pages/apps/usuarios/gruposSistema/form")
);

const AtualizarGrupo = React.lazy(
  () => import("../pages/apps/usuarios/gruposSistema/update")
);

const ListarEspecialidades = React.lazy(
  () => import("../pages/apps/configuracoes/especialidades")
);

const CriarEspecialidade = React.lazy(
  () => import("../pages/apps/configuracoes/especialidades/form")
);

const AtualizarEspecialidade = React.lazy(
  () => import("../pages/apps/configuracoes/especialidades/update")
);

const ListarCargos = React.lazy(
  () => import("../pages/apps/configuracoes/cargos")
);

const ListarPlanos = React.lazy(
  () => import("../pages/apps/configuracoes/planoSaude")
);

const ListarFeriados = React.lazy(
  () => import("../pages/apps/configuracoes/feriados")
);

const ListarPerfilPaciente = React.lazy(
  () => import("../pages/apps/configuracoes/perfilPaciente")
);

const ListarSalas = React.lazy(
  () => import("../pages/apps/configuracoes/salas")
);

const CriarSala = React.lazy(
  () => import("../pages/apps/configuracoes/salas/form")
);

const AtualizarSala = React.lazy(
  () => import("../pages/apps/configuracoes/salas/update")
);

// - colaborador
const Colaboradores = React.lazy(
  () => import("../pages/apps/Colaboradores/Lista")
);
const ColaboladorFicha = React.lazy(
  () => import("../pages/apps/Colaboradores/Ficha")
);

const RegistrarColaborador = React.lazy(
  () => import("../pages/apps/Colaboradores/FichaRegistro")
);

const ResetarSenha = React.lazy(() => import("../pages/auth/ResetarSenha"));

const Erro404 = React.lazy(() => import("../pages/auth/Erro404"));
const Erro404Unidade = React.lazy(() => import("../pages/auth/Erro404Unidade"));

// - paciente
const Pacientes = React.lazy(() => import("../pages/apps/Pacientes/Lista"));
const PacienteFicha = React.lazy(() => import("../pages/apps/Pacientes/Ficha"));
const PacienteRegistro = React.lazy(
  () => import("../pages/apps/Pacientes/FichaRegistro")
);

const MeuPerfil = React.lazy(() => import("../pages/meuPerfil"));

const Quadro = React.lazy(() => import("../pages/apps/Quadro"));

const Atendimentos = React.lazy(() => import("../pages/apps/Atendimentos"));

const Prontuarios = React.lazy(() => import("../pages/apps/Prontuario"));

const RelatoriosContratos = React.lazy(
  () => import("../pages/apps/relatorios/contratos")
);

const RelatoriosDadosGerais = React.lazy(
  () => import("../pages/apps/relatorios/relatorioDadosGerais/")
);

const DashboardAtendimentos = React.lazy(
  () => import("../pages/dashboard/Dashboard/DashboardAtendimentos")
);

const RelatorioDisponibilidade = React.lazy(
  () => import("../pages/apps/relatorios/RelatorioDisponibilidade")
);

const RelatorioDisponibilidadeSalas = React.lazy(
  () => import("../pages/apps/relatorios/relatorioDisponibilidadeSala/")
);

const Comunicados = React.lazy(
  () => import("../pages/comunicados/comunicados/Comunicados")
);

const DetalhesComunicado = React.lazy(
  () => import("pages/comunicados/detalhesComunicado/DetalhesComunicado")
);

const Impressao = React.lazy(() => import("pages/apps/impressao"));

const Feedback = React.lazy(() => import("pages/apps/Feedback"));

const FeedbacksGerais = React.lazy(() => import("pages/apps/FeedbacksGerais"));

const FeedbackNivelHierarquico = React.lazy(
  () => import("pages/apps/FeedbackNivelHierarquico")
);

const IntegracaoDropboxStatus = React.lazy(
  () => import("pages/apps/IntegracaoDropboxStatus")
);

const UltimasAtividadesDrive = React.lazy(
  () => import("pages/apps/configuracoes/UltimasAtividadesDrive")
);

const DocumentosAdministrativos = React.lazy(
  () => import("pages/apps/configuracoes/DocumentosAdministrativos")
);

const RelatorioPlano = React.lazy(
  () => import("../pages/apps/relatorios/relatorioPlano/")
);

const RelatorioProntuario = React.lazy(
  () => import("../pages/apps/relatorios/relatorioProntuario/")
);

const Pagamentos = React.lazy(() => import("pages/apps/Pagamentos"));

const SalaAgendamentos = React.lazy(() => import("../pages/apps/SalaAgendamentos"));

const DetalhesSalaAgendamento = React.lazy(() => import("../pages/apps/SalaAgendamentos/detalhes"))

const RegistroSalaAgendamento = React.lazy(() => import("../pages/apps/SalaAgendamentos/registro"))
export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  funcao?: number;
  component?: RouteProps["component"];
  route?: any;
  exact?: RouteProps["exact"];
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  component: () => <Root />,
  route: Route,
};

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      exact: true,
      route: PrivateRoute,
      funcao: 72,
    },
  ],
};

const dropboxStatus: RoutesProps = {
  path: "/dropbox/:status",
  name: "Dropbox",
  icon: "airplay",
  header: "Dropbox",
  component: IntegracaoDropboxStatus,
  route: PrivateRoute,
  exact: true,
};

const comunicadoRoutes: RoutesProps = {
  path: "/comunicados",
  name: "Comunicados",
  icon: "airplay",
  header: "Comunicados",
  children: [
    {
      path: "/comunicados",
      name: "Comunicados",
      component: Comunicados,
      exact: true,
      route: PrivateRoute,
    },
    {
      path: "/comunicados/detalhes/:id",
      name: "Detalhes comunicado",
      component: DetalhesComunicado,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const impressaoRoutes: RoutesProps = {
  path: "/imprimir",
  name: "Imprimir",
  icon: "airplay",
  header: "Imprimir",
  children: [
    {
      path: "/imprimir",
      name: "Imprimir",
      component: Impressao,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const rotaAtendimento: RoutesProps = {
  path: "/atendimentos",
  name: "Atendimentos",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/atendimentos",
      name: "Atendimentos",
      component: Atendimentos,
      exact: true,
      route: PrivateRoute,
      funcao: 50,
    },
  ],
};

const rotaSalaAgendamentos: RoutesProps = {
  path: "/salas/agendamentos",
  name: "Reservar sala",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/salas/agendamentos",
      name: "Reservar sala",
      component: SalaAgendamentos,
      exact: true,
      route: PrivateRoute,
      funcao: 50,
    },
  ],
};

const rotaDetalhesSalaAgendamento: RoutesProps = {
  path: "/salas/agendamentos/:id",
  name: "Detalhes sala agendamento",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/salas/agendamentos/detalhes/:id",
      name: "Detalhes sala agendamento",
      component: DetalhesSalaAgendamento,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const rotaRegistroSalaAgendamento: RoutesProps = {
  path: "/salas/agendamentos/registro",
  name: "Registrar sala agendamento",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/salas/agendamentos/registro",
      name: "Registrar sala agendamento",
      component: RegistroSalaAgendamento,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const rotaFeedbackNivelHierarquico: RoutesProps = {
  path: "/feedback-nivel/:id",
  name: "Feedback",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/feedback-nivel/:id",
      name: "Feedback",
      component: FeedbackNivelHierarquico,
      exact: true,
      route: PrivateRoute,
      funcao: 71,
    },
  ],
};

const rotaFeedback: RoutesProps = {
  path: "/feedback/:id",
  name: "Feedback",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/feedback/:id",
      name: "Feedback",
      component: Feedback,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const rotaFeedbacskGerais: RoutesProps = {
  path: "/feedbacks-gerais",
  name: "Feedbacks Gerais",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/feedbacks-gerais",
      name: "Feedbacks Gerais",
      component: FeedbacksGerais,
      exact: true,
      route: PrivateRoute,
      funcao: 70,
    },
  ],
};

const rotaProntuario: RoutesProps = {
  path: "/prontuario/:atendimentoId",
  name: "Prontuário",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/prontuario/:atendimentoId",
      name: "Prontuário",
      component: Prontuarios,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const calendarioAppRoutes: RoutesProps = {
  path: "/calendario",
  name: "Calendário",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/calendario",
      name: "Calendário",
      component: CalendarioApp,
      exact: true,
      route: PrivateRoute,
      funcao: 44,
    },
  ],
};

const userRoutes: RoutesProps = {
  path: "/listagem-usuarios",
  name: "Usuários",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-usuarios",
      name: "Usuários",
      funcao: 7,
      component: Users,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const lastActivititesRoute: RoutesProps = {
  path: "/ultimas-atividades",
  name: "Últimas atividades do Drive",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/ultimas-atividades",
      name: "Últimas atividades do Drove",
      funcao: 73,
      component: UltimasAtividadesDrive,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const admDocumentsRoute: RoutesProps = {
  path: "/documentos-administrativos",
  name: "Documentos administrativos",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/documentos-administrativos",
      name: "Documentos administrativos",
      funcao: 77,
      component: DocumentosAdministrativos,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const directorsDocumentsRoute: RoutesProps = {
  path: "/documentos-diretoria",
  name: "Documentos da diretoria",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/documentos-diretoria",
      name: "Documentos da diretoria",
      funcao: 81,
      component: DocumentosAdministrativos,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const psychologyDocumentsRoute: RoutesProps = {
  path: "/documentos-psicologia",
  name: "Documentos Psicologia",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/documentos-psicologia",
      name: "Documentos Psicologia",
      funcao: 92,
      component: DocumentosAdministrativos,
      exact: true,
      route: PrivateRoute,
    },
  ],
};
const avRvDocumentsRoute: RoutesProps = {
  path: "/documentos-avaliacao-reavaliacao",
  name: "Documentos Avaliação e Reavaliação",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/documentos-avaliacao-reavaliacao",
      name: "Documentos Avaliação e Reavaliação",
      funcao: 97,
      component: DocumentosAdministrativos,
      exact: true,
      route: PrivateRoute,
    },
  ],
};
const coordinationDocumentsRoute: RoutesProps = {
  path: "/documentos-coordenacao",
  name: "Documentos da Coordenação",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/documentos-coordenacao",
      name: "Documentos da Coordenação",
      funcao: 102,
      component: DocumentosAdministrativos,
      exact: true,
      route: PrivateRoute,
    },
  ],
};
const socialGroupDocumentsRoute: RoutesProps = {
  path: "/documentos-grupo-social",
  name: "Documentos Grupos Sociais",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/documentos-grupo-social",
      name: "Documentos da Coordenação",
      funcao: 107,
      component: DocumentosAdministrativos,
      exact: true,
      route: PrivateRoute,
    },
  ],
};
const materialDocumentsRoute: RoutesProps = {
  path: "/documentos-materiais",
  name: "Documentos de Materiais",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/documentos-materiais",
      name: "Documentos de Materiais",
      funcao: 112,
      component: DocumentosAdministrativos,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const rotaMeuPerfil: RoutesProps = {
  path: "/meu-perfil",
  name: "Dashboard Atendimentos",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/meu-perfil",
      name: "Atualizar usuário",
      exact: true,
      component: MeuPerfil,
      route: PrivateRoute,
    },
  ],
};

const rotaAtualizarUsuario: RoutesProps = {
  path: "/usuarios/:id",
  name: "Atualizar usuário",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/usuarios/:id",
      name: "Atualizar usuário",
      component: CriarAtualizarUsuario,
      exact: true,
      funcao: 17,
      route: PrivateRoute,
    },
  ],
};

const rotaDashboardAtendimentos: RoutesProps = {
  path: "/painel-consulta",
  name: "Dashboard Atendimentos",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/painel-consulta",
      name: "Dashboard Atendimentos",
      component: DashboardAtendimentos,
      exact: true,
      //funcao: 17,
      route: PrivateRoute,
    },
  ],
};

const rotaRelatorioDisponibilidade: RoutesProps = {
  path: "/relatorio-disponibilidade",
  name: "Relatório Disponibilidade",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/relatorio-disponibilidade",
      name: "Relatório Disponibilidade",
      component: RelatorioDisponibilidade,
      exact: true,
      //funcao: 17,
      route: PrivateRoute,
    },
  ],
};

const rotaRelatorioDisponibilidadeSala: RoutesProps = {
  path: "/relatorio-disponibilidade-sala",
  name: "Relatório Disponibilidade das Salas",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/relatorio-disponibilidade-sala",
      name: "Relatório Disponibilidade das Salas",
      component: RelatorioDisponibilidadeSalas,
      exact: true,
      //funcao: 17,
      route: PrivateRoute,
    },
  ],
};

const rotaRelatorioPlano: RoutesProps = {
  path: "/relatorio-plano",
  name: "Relatório de Plano",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/relatorio-plano",
      name: "Relatório de Plano",
      component: RelatorioPlano,
      exact: true,
      //funcao: 17,
      route: PrivateRoute,
    },
  ],
};

const rotaRelatorioProntuario: RoutesProps = {
  path: "/relatorio-prontuario",
  name: "Relatório de Prontuário",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/relatorio-prontuario",
      name: "Relatório de Prontuário",
      component: RelatorioProntuario,
      exact: true,
      //funcao: 17,
      route: PrivateRoute,
    },
  ],
};
      
const rotaCriarUsuario: RoutesProps = {
  path: "/usuarios",
  name: "Criar usuário",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/usuarios",
      name: "Criar usuário",
      component: CriarAtualizarUsuario,
      exact: true,
      funcao: 18,
      route: PrivateRoute,
    },
  ],
};

const rotaGruposSistema: RoutesProps = {
  path: "/listagem-grupos-sistema",
  name: "Grupos de sistema",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-grupos-sistema",
      name: "Grupos de sistema",
      component: SystemGroups,
      exact: true,
      funcao: 8,
      route: PrivateRoute,
    },
  ],
};

const rotaAtualizarGruposSistema: RoutesProps = {
  path: "/grupos-sistema/:id",
  name: "Atualizar grupos de sistema",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/grupos-sistema/:id",
      name: "Atualizar grupos de sistema",
      component: AtualizarGrupo,
      exact: true,
      funcao: 20,
      route: PrivateRoute,
    },
  ],
};

const rotaCriarGruposSistema: RoutesProps = {
  path: "/grupos-sistema",
  name: "Criar grupos de sistema",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/grupos-sistema",
      name: "Criar grupos de sistema",
      component: CreateGroupsSystem,
      exact: true,
      funcao: 19,
      route: PrivateRoute,
    },
  ],
};

const rotaListarPerfilPaciente: RoutesProps = {
  path: "/listagem-perfil-paciente",
  name: "Listar Perfil de paciente",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-perfil-paciente",
      name: "Listar Perfil de paciente",
      component: ListarPerfilPaciente,
      exact: true,
      funcao: 12,
      route: PrivateRoute,
    },
  ],
};

const rotaListarHorario: RoutesProps = {
  path: "/listagem-horarios",
  name: "Listar horários",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-horarios",
      name: "Listar horários",
      component: ListagemHorario,
      exact: true,
      funcao: 15,
      route: PrivateRoute,
    },
  ],
};

const rotaListarTipoHorario: RoutesProps = {
  path: "/listagem-tipo-horario",
  name: "Listar Tipos de horários",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-tipo-horario",
      name: "Listar Tipos de horários",
      component: ListagemTipoHorario,
      exact: true,
      funcao: 15,
      route: PrivateRoute,
    },
  ],
};

const rotaAtualizarTipoHorario: RoutesProps = {
  path: "/tipo-horario/:id",
  name: "Atualizar Tipos de Horário",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/tipo-horario/:id",
      name: "Atualizar Tipos de Horário",
      component: UpdateTipoHorario,
      exact: true,
      funcao: 20,
      route: PrivateRoute,
    },
  ],
};

const rotaChecklistsPersonalizados: RoutesProps = {
  path: "/listagem-checklists",
  name: "Checklists",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-checklists",
      name: "Listar checklists",
      component: ChecklistsPersonalizados,
      exact: true,
      funcao: 118,
      route: PrivateRoute,
    },
  ],
};

const rotaListagemTipoContrato: RoutesProps = {
  path: "/tipos-contratos",
  name: "Tipos de contratos",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/tipos-contratos",
      name: "Tipos de contratos",
      component: ListagemTipoContrato,
      exact: true,
      funcao: 119,
      route: PrivateRoute,
    },
  ],
};

const rotaListagemEmpresas: RoutesProps = {
  path: "/empresas",
  name: "Empresas",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/empresas",
      name: "Empresas",
      component: ListagemEmpresas,
      exact: true,
      funcao: 119,
      route: PrivateRoute,
    },
  ],
};

const rotaEditarProtocoloSocial: RoutesProps = {
  path: "/protocolo-social/editar",
  name: "Protocolo Social",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/protocolo-social/editar",
      name: "Protocolo Social",
      component: ProtocoloSocialEdicao,
      exact: true,
      funcao: 123,
      route: PrivateRoute,
    },
  ],
};

const rotaVisualizarProtocoloSocial: RoutesProps = {
  path: "/protocolo-social/visualizar",
  name: "Protocolo Social",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/protocolo-social/visualizar",
      name: "Protocolo Social",
      component: ProtocoloSocialVisualizar,
      exact: true,
      funcao: 122,
      route: PrivateRoute,
    },
  ],
};

const rotaListagemTipoFeedback: RoutesProps = {
  path: "/tipo-feedback",
  name: "Tipo Feedback",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/tipo-feedback",
      name: "Tipo Feedback",
      component: ListagemTipoFeedback,
      exact: true,
      funcao: 121,
      route: PrivateRoute,
    },
  ],
};

const rotaCriarTipoHorario: RoutesProps = {
  path: "/tipo-horario",
  name: "Criar Tipos de Horário",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/tipo-horario",
      name: "Criar Tipos de Horário",
      component: CreateTipoHorario,
      exact: true,
      funcao: 19,
      route: PrivateRoute,
    },
  ],
};

const rotaListarSalas: RoutesProps = {
  path: "/listagem-salas",
  name: "Listar Salas",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-salas",
      name: "Listar Salas",
      component: ListarSalas,
      exact: true,
      funcao: 13,
      route: PrivateRoute,
    },
  ],
};

const rotaAtualizarSala: RoutesProps = {
  path: "/sala/:id",
  name: "Atualizar sala",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/sala/:id",
      name: "Atualizar sala",
      component: AtualizarSala,
      exact: true,
      funcao: 29,
      route: PrivateRoute,
    },
  ],
};

const rotaCriarSala: RoutesProps = {
  path: "/sala",
  name: "Criar sala",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/sala",
      name: "Criar sala",
      component: CriarSala,
      exact: true,
      funcao: 27,
      route: PrivateRoute,
    },
  ],
};

const rotaListarUnidades: RoutesProps = {
  path: "/listagem-unidades",
  name: "Listar unidades",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-unidades",
      name: "Listar unidades",
      component: Unidades,
      exact: true,
      funcao: 14,
      route: PrivateRoute,
    },
  ],
};

const rotaAtualizarEspecialidade: RoutesProps = {
  path: "/especialidade/:id",
  name: "Atualizar especialidade",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/especialidade/:id",
      name: "Atualizar especialidade",
      component: AtualizarEspecialidade,
      exact: true,
      funcao: 21,
      route: PrivateRoute,
    },
  ],
};

const rotaCriarEspecialidade: RoutesProps = {
  path: "/especialidade",
  name: "Criar especialidade",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/especialidade",
      name: "Criar especialidade",
      component: CriarEspecialidade,
      exact: true,
      funcao: 22,
      route: PrivateRoute,
    },
  ],
};

const rotaListarEspecialidades: RoutesProps = {
  path: "/listagem-especialidades",
  name: "Especialidades",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-especialidades",
      name: "Especialidades",
      component: ListarEspecialidades,
      exact: true,
      funcao: 10,
      route: PrivateRoute,
    },
  ],
};

const rotaListarCargos: RoutesProps = {
  path: "/listagem-cargo",
  name: "Cargos",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-cargo",
      name: "Cargos",
      component: ListarCargos,
      exact: true,
      funcao: 11,
      route: PrivateRoute,
    },
  ],
};

const rotaListarPlanos: RoutesProps = {
  path: "/plano-saude",
  name: "Planos de saúde",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/plano-saude",
      name: "Planos de saúde",
      component: ListarPlanos,
      exact: true,
      funcao: 59,
      route: PrivateRoute,
    },
  ],
};

const rotaListarFeriados: RoutesProps = {
  path: "/listagem-feriado",
  name: "Feriados",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/listagem-feriado",
      name: "Feriados",
      component: ListarFeriados,
      exact: true,
      funcao: 11,
      route: PrivateRoute,
    },
  ],
};

const rotaCriarColaborador: RoutesProps = {
  path: "/colaboradores/registro",
  name: "Registrar colaborador",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/colaboradores/registro",
      name: "Registrar colaborador",
      component: RegistrarColaborador,
      exact: true,
      funcao: 3,
      route: PrivateRoute,
    },
  ],
};

const rotaQuadro: RoutesProps = {
  path: "/quadro/:data",
  name: "Quadro",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/quadro/:data",
      name: "Quadro",
      component: Quadro,
      exact: true,
      //funcao: 8,
      route: PrivateRoute,
    },
  ],
};

const colaboradoresAppRoutes = {
  path: "/apps/colaboradores",
  name: "Colaboradores",
  route: PrivateRoute,
  icon: "uil-briefcase",

  children: [
    {
      path: "/colaboradores/listagem",
      name: "Lista",
      component: Colaboradores,
      exact: true,
      funcao: 2,
      route: PrivateRoute,
    },
    {
      path: "/colaboradores/ficha/:id",
      name: "Ficha",
      component: ColaboladorFicha,
      exact: true,
      funcao: 4,
      route: PrivateRoute,
    },
  ],
};

const rotaRelatoriosContratos: RoutesProps = {
  path: "/relatorios-contratos",
  name: "Relatorios Contratos",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/relatorios-contratos",
      name: "Relatorios Contratos",
      component: RelatoriosContratos,
      exact: true,
      funcao: 57,
      route: PrivateRoute,
    },
  ],
};

const rotaRelatoriosDadosGerais: RoutesProps = {
  path: "/relatorio-dados-gerais",
  name: "Relatorios Dados Gerais",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/relatorio-dados-gerais",
      name: "Relatorios Dados Gerais",
      component: RelatoriosDadosGerais,
      exact: true,
      funcao: 63,
      route: PrivateRoute,
    },
  ],
};

const pacientesAppRoutes = {
  path: "/apps/pacientes",
  name: "Pacientes",
  route: PrivateRoute,
  icon: "uil-briefcase",

  children: [
    {
      path: "/pacientes/listagem",
      name: "Lista",
      component: Pacientes,
      exact: true,
      route: PrivateRoute,
      funcao: 45,
    },
    {
      path: "/pacientes/ficha/:id",
      name: "Ficha",
      component: PacienteFicha,
      exact: true,
      route: PrivateRoute,
    },
    {
      path: "/pacientes/registro",
      name: "Registro",
      component: PacienteRegistro,
      exact: true,
      route: PrivateRoute,
    },
  ],
};

const rotaPagamentos: RoutesProps = {
  path: "/pagamentos",
  name: "Pagamentos",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/pagamentos",
      name: "Pagamentos",
      component: Pagamentos,
      exact: true,
      route: PrivateRoute,
      funcao: 70,
    },
  ],
};

const appRoutes = [colaboradoresAppRoutes, pacientesAppRoutes];

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    exact: true,
    route: Route,
  },
  {
    path: "/esqueceu-senha/:hash",
    name: "Atualizar a senha",
    component: ResetarSenha,
    exact: true,
    route: Route,
  },
  {
    path: "/esqueceu-senha",
    name: "Esqueceu a senha",
    component: ForgetPassword,
    exact: true,
    route: Route,
  },
  {
    path: "/primeiro-acesso",
    name: "Primeiro acesso",
    component: FirstAccess,
    exact: true,
    route: Route,
  },
  {
    path: "/precadastro-pacientes/:unidade_id",
    name: "Criar Paciente",
    component: RegistrarPaciente,
    exact: true,
    route: Route,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    exact: true,
    route: Route,
  },
  {
    path: "/404",
    name: "404",
    component: Erro404,
    route: Route,
  },
  {
    path: "/unidade-404",
    name: "Unidade 404",
    component: Erro404Unidade,
    route: Route,
  },
];

// public routes

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  rootRoute,
  comunicadoRoutes,
  impressaoRoutes,
  dashboardRoutes,
  calendarioAppRoutes,
  userRoutes,
  rotaFeedbacskGerais,
  rotaAtualizarUsuario,
  rotaFeedbackNivelHierarquico,
  rotaFeedback,
  rotaCriarUsuario,
  rotaGruposSistema,
  rotaAtualizarGruposSistema,
  rotaCriarGruposSistema,
  rotaListarEspecialidades,
  rotaListarCargos,
  rotaListarPlanos,
  rotaListarFeriados,
  rotaListarPerfilPaciente,
  rotaListarSalas,
  rotaCriarColaborador,
  rotaListarUnidades,
  rotaListarHorario,
  rotaListarTipoHorario,
  rotaCriarTipoHorario,
  rotaAtualizarTipoHorario,
  rotaChecklistsPersonalizados,
  rotaListagemTipoContrato,
  rotaListagemEmpresas,
  rotaEditarProtocoloSocial,
  rotaVisualizarProtocoloSocial,
  rotaListagemTipoFeedback,
  rotaAtualizarEspecialidade,
  rotaCriarEspecialidade,
  rotaMeuPerfil,
  rotaAtualizarSala,
  rotaCriarSala,
  rotaQuadro,
  rotaAtendimento,
  rotaProntuario,
  rotaRelatoriosContratos,
  rotaRelatoriosDadosGerais,
  rotaDashboardAtendimentos,
  rotaRelatorioDisponibilidade,
  rotaRelatorioDisponibilidadeSala,
  dropboxStatus,
  lastActivititesRoute,
  admDocumentsRoute,
  psychologyDocumentsRoute,
  avRvDocumentsRoute,
  materialDocumentsRoute,
  coordinationDocumentsRoute,
  socialGroupDocumentsRoute,
  directorsDocumentsRoute,
  rotaRelatorioPlano,
  rotaPagamentos,
  rotaRelatorioProntuario,
  rotaSalaAgendamentos,
  rotaDetalhesSalaAgendamento,
  rotaRegistroSalaAgendamento,
  ...appRoutes,
];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
