import { APICore } from "./apiCore";
import { AxiosRequestConfig } from "axios";

const api = new APICore();

export function get<T = any>(path: string, params?: { [key: string]: any }) {
  return api.get<T>(path, params);
}

export function post<T = any>(
  path: string,
  params: { [key: string]: any },
  config?: AxiosRequestConfig
) {
  return api.create<T>(path, params, config);
}

export function remove(path: string, config?: AxiosRequestConfig) {
  return api.delete(path, config);
}

export function put<T = any>(
  path: string,
  params: { [key: string]: any },
  config?: AxiosRequestConfig
) {
  return api.update<T>(path, params, config);
}
