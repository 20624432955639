import { APICore } from "./apiCore";

const api = new APICore();

const criarEspecialidade = (params: { descricao: string }) => {
    return api.create("/especialidades", params);
};

const atualizarEspecialidade = (id: string, params: any) => {
    return api.update(`/especialidades/${id}`, params);
};

const deletarEspecialidade = (id: string) => {
    return api.delete(`/especialidades/${id}`);
};

const getEspecialidade = (id: string) => {
    return api.get(`/especialidades/${id}`);
};

const getEspecialidades = (queryString?: string) => {
    return api.get(`/especialidades${queryString ? queryString : ""}`);
};

const getEspecialidadesUnidade = (queryString?: string) => {
    return api.get(`/especialidades-unidade${queryString ? queryString : ""}`);
};

export {
    criarEspecialidade,
    atualizarEspecialidade,
    deletarEspecialidade,
    getEspecialidade,
    getEspecialidades,
    getEspecialidadesUnidade
};
