import { APICore } from "./apiCore";

const api = new APICore();

const getRelatorioDisponibilidade = (queryString?: string) => {
    return api.get(`/relatorio-disponibilidade${queryString ? queryString : ''}`)
}

export {
    getRelatorioDisponibilidade
}