import { APICore } from "./apiCore";

const api = new APICore();

const criarHorario = (params: { descricao: string }) => {
    return api.create("/horario", params);
};

const atualizarHorario = (id: string, params: any) => {
    return api.update(`/horario/${id}`, params);
};

const deletarHorario = (id: string) => {
    return api.delete(`/horario/${id}`);
};

const listarHorarios = (queryString?: string) => {
    return api.get(`/horario${queryString ? queryString : ""}`);
};

const listarHorariosDisponiveis = (queryString?: string) => {
    return api.get(`/horario-disponibilidade${queryString ? queryString : ""}`);
};

const listarHorario = (id: string) => {
    return api.get(`/horario/${id}`);
};

export {
    criarHorario,
    atualizarHorario,
    deletarHorario,
    listarHorarios,
    listarHorariosDisponiveis,
    listarHorario
};
